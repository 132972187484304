import React from "react";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { graphql, StaticQuery } from "gatsby";
import PersonCard from "./PersonCard";

import "./PeopleList.scss";

interface PeopleListProps {
  team: {
    members: Person[];
    id: string;
    title: string;
    className?: string;
  };
  peopleImages?: ChildImageSharp[];
  DisplayCard?: React.FC<PersonCardProps>;
  filterFunc?: (person: Person) => boolean;
  className?: string;
}

const PeopleList: React.FC<PeopleListProps> = ({
  team: { members, id },
  peopleImages = [],
  DisplayCard = PersonCard,
  filterFunc = undefined,
  className = null,
}) => {
  const filteredMembers = filterFunc ? members.filter(filterFunc) : members;

  return (
    <ul
      className={classNames("people-list", className)}
      id={id}
      data-testid="peopleList"
    >
      {filteredMembers.map((member) => {
        const personImage = peopleImages.find((img) => img.name === member.img);
        return (
          <DisplayCard
            person={member}
            personImage={personImage}
            key={kebabCase(member.name)}
          />
        );
      })}
    </ul>
  );
};

export default (props: PeopleListProps): JSX.Element => (
  <StaticQuery
    query={graphql`
      query PeopleImagesQuery {
        allFile(filter: { relativeDirectory: { eq: "people" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
            name
          }
        }
      }
    `}
    render={(data) => (
      <PeopleList peopleImages={data.allFile.nodes} {...props} />
    )}
  />
);
