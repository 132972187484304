import React from "react";
import { kebabCase } from "lodash";
import { getSrc } from "gatsby-plugin-image";
import LinkedInImage from "../images/logo-linkedin.png";

import "./PersonCard.scss";

const PersonCard: React.FC<PersonCardProps> = ({ person, personImage }) => {
  const profileImageUrl = getSrc(personImage?.childImageSharp?.gatsbyImageData);
  return (
    <li className="person">
      <div
        className="card"
        id={kebabCase(`${person.name} ${person.team}`)}
        data-testid="personCard"
      >
        {/* NOTE: We can switch back to using GatsbyImage when this bug is fixed:
        https://github.com/gatsbyjs/gatsby/issues/31669 */}
        {profileImageUrl ? (
          <img
            src={profileImageUrl}
            alt={person.name}
            className="card__image"
            data-testid="personImage"
          />
        ) : null}
        <div className="person__info">
          <div>
            {person.team && (
              <small className="person__team">{person.team} </small>
            )}
            <h3 className="person__name">{person.name}</h3>
            <p className="person__focus">{person.role}</p>
          </div>
          {person.linkedin && (
            <address>
              <a
                className="person__social-link"
                href={person.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkedInImage}
                  alt={`Go to ${person.name}'s LinkedIn Profile`}
                />
              </a>
            </address>
          )}
        </div>
      </div>
    </li>
  );
};

export default PersonCard;
