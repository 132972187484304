import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Masthead from "../components/Masthead";
import Hero from "../components/Hero";
import HeadingAnchor from "../components/HeadingAnchor";
import PeopleList from "../components/PeopleList";
import OurValues from "../components/OurValues";
import PersonCard from "../components/PersonCard";

import "../styles/team.scss";

const TeamPage: React.FC<TeamProps> = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    contentJson: { studioTeam, entrepreneurs, ideas42Team, advisors, board },
  },
  location: { pathname },
}) => {
  const teams = [
    {
      members: studioTeam,
      title: "Venture Studio team",
      id: "studio-team",
      className: "team__studio",
    },
    {
      members: entrepreneurs,
      title: "Current Entrepreneurs",
      id: "entrepreneurs-team",
      className: "team__entrepreneurs",
    },
    {
      members: ideas42Team,
      title: "ideas42 Team",
      id: "ideas42-team",
      className: "team__ideas42",
    },
    {
      members: advisors,
      title: "Advisors",
      id: "advisors",
      className: "team__advisors",
    },
    {
      members: board,
      title: "ideas42 Ventures Board",
      id: "board",
      className: "team__advisors",
    },
  ];

  const pageTitle = "ideas42 Venture Studio - Team";

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={siteUrl + pathname} />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <Masthead>
        <Hero
          tag="The team"
          title="Meet the people of the ideas42 Venture Studio"
          body="The ideas42 Venture Studio is made up of entrepreneurs, core members,
            contributors from ideas42, board members, and advisors. Our goal is to
            give our entrepreneurs access to the widest range of experiences and
            expertise as possible."
        />
      </Masthead>
      <>
        {teams.map((team) => (
          <section
            className={`standard-restricted-width ${team.className}`}
            key={team.id}
          >
            <HeadingAnchor level="2" text={team.title} id={team.id} />
            <PeopleList team={team} DisplayCard={PersonCard} />
          </section>
        ))}
        <OurValues />
      </>
    </Layout>
  );
};

export default (props: TeamProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query TeamPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        contentJson {
          studioTeam {
            img
            name
            linkedin
            role
          }
          entrepreneurs {
            img
            name
            linkedin
            role
          }
          ideas42Team {
            img
            linkedin
            name
            role
          }
          advisors {
            img
            linkedin
            name
            role
          }
          board {
            img
            linkedin
            name
            role
          }
        }
      }
    `}
    render={(data) => <TeamPage data={data} {...props} />}
  />
);
