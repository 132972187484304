import React from "react";
import HeadingAnchor from "./HeadingAnchor";

const OurValues: React.FC = () => (
  <div className="standard-restricted-width">
    <div className="g-max-3 g-centered font-size-3">
      <HeadingAnchor level="2" text="Our Values" id="our-values" />
      <p>
        At the ideas42 Venture Studio, our values are not words that guide our
        actions, but instead words that reflect who we are, what we do, and how
        we do it. While our business building model is constantly evolving, our
        actions, and thus our values are not.
      </p>
      <p>
        We are humble and ambitious, and see no conflict between those two. We
        trust each other, but are not afraid to ask questions (easy and hard
        ones). We share in our successes and failures, but understand that being
        low ego doesn’t mean having no ego. We are transparent, diverse, and
        always learning. Sometimes we get things wrong.
      </p>
      <p>
        We don’t believe we can solve every social problem (humble), but we
        believe that we can make things significantly better (ambitious), while
        not supporting or maintaining power for structurally racist, sexist or
        otherwise inequitable entities.
      </p>
    </div>
  </div>
);

export default OurValues;
